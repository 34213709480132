const TMS_TOKEN_KEY = 'TMS_TOKEN';
const TMS_ORGANIZATION_ID_KEY = 'TMS_ORGANIZATION_ID_KEY';
const QINIU_CDN_URL = 'https://cdn.intimetms.com/';
const MENU_AUTH_LIST = 'MENU_AUTH_LIST';

export { TMS_TOKEN_KEY, TMS_ORGANIZATION_ID_KEY, QINIU_CDN_URL, MENU_AUTH_LIST };

export const REFRESH_TERRITORYS_EVENT = 'refreshTerritorysEvent';

export const REFRESH_ORDERS_EVENT = 'refreshOrdersEvent';

export const REFRESH_ROUTERS_EVENT = 'refreshRoutersEvent';

export const CHANGE_TERRITORY_POLYGON_VALUE_EVENT = 'changeTerritoryPolygonValueEvent';

export const SHOW_ROUTER_EVENT = 'showRouterEvent';

export const CHANGE_SHOW_ROUTER_EVENT = 'changShowRouterEvent';

export const ADD_TAB_WIDTH_EVENT = 'addTabWidthEvent';

export const UPDATE_UNREAD_COUNT_EVENT = 'updateUnreadCountEvent';

export const CLOSE_ROUTER_DETAIL_EVENT = 'closeRouterDetailEvent';

export const UPDATE_MAP_RESIZE = 'updateMapResize';

export const REPLAN_ROUTER = 'replanRouter';

export const ROUTER_REFRESH = 'routerRefresh';

export const CHANGE_ROUTER_POINTS = 'changeRouterPoints';

export const UPDATE_MARKER_VISIBILITY = 'updateMarkerVisibility';

export const RENDER_ROUTERS = 'renderRouters';

export const RENDER_DRIVERS = 'renderDrivers';

export const RENDER_TERRITORYS = 'renderTerritorys';

export const RENDER_ORDERS = 'renderOrders';

export const SHOW_ROUTER_DETAIL = 'showRouterDetail';

export const UPDATE_TAB_KEY = 'updateTabKey';

export const OPEN_LOGIN_MODAL = 'openLoginModal';
